import { useFormatMessage } from '../../helpers/intl'

export const useTransactionSearchTranslation = () => {
  const startMethodText = useFormatMessage('dashboard.transactionsPage.advancedSearch.startMethod', 'Start Method')
  const stateText = useFormatMessage('dashboard.transactionsPage.advancedSearch.state', 'State')
  const transactionIDText = useFormatMessage('dashboard.selector.transactionID', 'Transaction ID')
  const tranasctionStateText = useFormatMessage('dashboard.text.transactionState.heading', 'Transaction State')
  const rfidNumberText = useFormatMessage('dashboard.transactionPage.rfid', 'RFID Number')
  return { startMethodText, stateText, transactionIDText, tranasctionStateText, rfidNumberText }
}
