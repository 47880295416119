import configProvider from '../../config'
import { addQueryString } from '../../helpers/url'
import { log } from '../../logger'
import { mockUsers, mockUserWallet } from '../../mock/user-mock'
import { AugmentedUserResponse, UserResponse } from '../../models/http'
import { TenantRef } from '../../models/tenant'
import {
  AugmentedUser,
  DashboardAccessType,
  toAugmentedUser,
  User,
  UserEmailResponse,
  UserInviteSuccess,
  UserPermissions,
  UserRef,
  UserRole,
  UserUpdate,
  UserWallet,
} from '../../models/user'
import { apiClient } from './client'
import { mockAugmentedUserResponse, mockUserResponse } from '../../mock/user-mock'
import { usersQuery } from 'helpers/query/UsersQuery'
import { returnMock } from './mock'
import { mockListing } from 'mock/listing-mock'
import { mockAccessPlanSchedule } from 'mock/access-plan-schedule-mock'

export async function GetUsers(
  page: number,
  isMockUpEnabled: boolean,
  term?: string,
  defaultTenant?: TenantRef,
  tenants?: TenantRef[],
): Promise<AugmentedUserResponse> {
  let url = '/users'

  const queryParams = usersQuery({ page, term, defaultTenant, tenant: tenants })

  url = addQueryString(url, queryParams)

  log('fetching users URL', { url })
  if (isMockUpEnabled) {
    return Promise.resolve(mockAugmentedUserResponse(term))
  }

  return apiClient<UserResponse>(url, {}).then((resp: UserResponse) => {
    return {
      ...resp,
      data: resp.data.map((u) => toAugmentedUser(u)),
    }
  })
}

export async function FindUser(term?: string, tenants?: TenantRef[]): Promise<AugmentedUser> {
  let url = '/users/find'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (tenants && tenants.length > 0) {
    queryParams['tenant_id'] = `${tenants.map((t) => t.id)}`
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse(term).data[0]))
  }

  return apiClient<User>(url, {}).then((u) => toAugmentedUser(u))
}

export async function SearchUsers(term?: string, country?: string): Promise<UserWallet[]> {
  let url = '/users/search'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  if (country) {
    queryParams['country'] = country
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserWallet)
  }
  return apiClient(url, {})
}

export async function SearchForExistingEmail(
  isMockUpEnabled: boolean,
  term?: string,
  country?: string,
): Promise<UserEmailResponse> {
  let url = '/users/check_email_exist'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  url = addQueryString(url, queryParams)

  if (isMockUpEnabled) {
    return Promise.resolve({ email_exist: true, person_id: 'jXD9CMKXeavenIGoSK6qDQ', person_name: term?.split('@')[0] })
  }

  return apiClient(url, {})
}

export async function InviteUser(
  tenants: UserPermissions[],
  email: string,
  name: string,
  // role: TenantPermissionType,
  id: string,
): Promise<User> {
  const url = '/users/invite'

  // const tenantsArr: UserPermissions[] = []

  // tenants.map(tenant => {
  //   tenantsArr.push({id: tenant.id, name: tenant.name, access: role, driver: false})
  // })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserResponse()['data'][0])
  }

  log('adding User', { url: url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tenants,
      users: [
        {
          id: id,
          email: email,
          name: name,
          // leaving note as empty string means no update in name
          note: '',
        },
      ],
    }),
  })
}

export async function NewInviteUser(
  tenants: UserPermissions[],
  users: UserRef[],
  accessPlanIds: number[],
  isMockUpEnabled: boolean,
): Promise<User> {
  const url = '/users/invite'

  if (isMockUpEnabled) {
    const newUserInvited: User = {
      id: `yCcmQZxNAGrG0WTgq6uUTQ${mockUsers.length + 1}`.toString(),
      email: users.map((u) => u.email).join(','),
      name: users.map((u) => u.name).join(','),
      role: 'user',
      note: '',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '42',
          permissions: ['tenant.driver'],
          display: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
          displayName: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
        },
        {
          resourceType: 'Tenant',
          resourceId: '109',
          permissions: ['tenant.manager'],
          display: 'opus',
          displayName: 'opus',
        },
        {
          resourceType: 'Tenant',
          resourceId: '56',
          permissions: ['tenant.charger_operator'],
          display: 'Royal-Botanical-Gardens-ON',
          displayName: 'Royal Botanical Gardens',
        },
      ],
      subscriptions: [
        {
          id: 1,
          personId: 3,
          planId: 2,
          startDate: '2021-09-01',
          endDate: '2021-09-30',
          status: 'active',
          createdAt: '2021-09-01',
          updatedAt: '2021-09-01',
          quantity: 1,
          subscriber: {
            id: '3',
            email: 'lan2@gmail.com',
            name: 'Lan2',
          },
        },
      ],
      accessPlans: [
        {
          id: 1,
          name: 'another access plan name 14',
          tenant_id: 1,
          listing_ids: [mockListing['L6652'].id],
          access_type: 'unrestricted',
          email_domains: ['swtchenergy.com', 'amazon.com'],
          schedules: mockAccessPlanSchedule.customSchedule,
          has_operation_hours: true,
          enable_access_code: true,
          enable_email_domains: true,
          access_code: 'qwerty',
          created_at: '2024-09-16T19:29:10.891Z',
          updated_at: '2024-10-15T16:52:22.026Z',
        },
      ],
    }
    return Promise.resolve(newUserInvited)
  }

  log('adding User', { url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tenants,
      users: users,
      access_plan_ids: accessPlanIds,
    }),
  })
}

export async function NewInviteUserEmails(
  tenants: UserPermissions[],
  users: { email: string }[],
  accessPlanIds: number[],
  isMockUpEnabled: boolean,
): Promise<User | UserInviteSuccess> {
  const url = '/users/send_invitation_and_assign_role'

  if (isMockUpEnabled) {
    const newUserInvited: User = {
      id: `yCcmQZxNAGrG0WTgq6uUTQ${mockUsers.length + 1}`.toString(),
      email: users.map((u) => u.email).join(','),
      name: users.map((u, index) => `User - ${index + 1}`).join(','),
      role: 'user',
      note: '',
      accesses: [
        {
          resourceType: 'Tenant',
          resourceId: '42',
          permissions: ['tenant.driver'],
          display: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
          displayName: '100-Victoria-St-S-Kitchener-ON-N2G2B3',
        },
        {
          resourceType: 'Tenant',
          resourceId: '109',
          permissions: ['tenant.manager'],
          display: 'opus',
          displayName: 'opus',
        },
        {
          resourceType: 'Tenant',
          resourceId: '56',
          permissions: ['tenant.charger_operator'],
          display: 'Royal-Botanical-Gardens-ON',
          displayName: 'Royal Botanical Gardens',
        },
      ],
      subscriptions: [
        {
          id: 1,
          personId: 3,
          planId: 2,
          startDate: '2021-09-01',
          endDate: '2021-09-30',
          status: 'active',
          createdAt: '2021-09-01',
          updatedAt: '2021-09-01',
          quantity: 1,
          subscriber: {
            id: '3',
            email: 'lan2@gmail.com',
            name: 'Lan2',
          },
        },
      ],
      accessPlans: [
        {
          id: 1,
          name: 'another access plan name 14',
          tenant_id: 1,
          listing_ids: [mockListing['L6652'].id],
          access_type: 'unrestricted',
          email_domains: ['swtchenergy.com', 'amazon.com'],
          schedules: mockAccessPlanSchedule.customSchedule,
          has_operation_hours: true,
          enable_access_code: true,
          enable_email_domains: true,
          access_code: 'qwerty',
          created_at: '2024-09-16T19:29:10.891Z',
          updated_at: '2024-10-15T16:52:22.026Z',
        },
      ],
    }
    return Promise.resolve(newUserInvited)
  }

  log('adding User', { url })
  return apiClient(url, {
    method: 'POST',
    body: JSON.stringify({
      tenants,
      users: users,
      access_plan_ids: accessPlanIds,
    }),
  })
}
export async function ToggleRole(userId: string, role: UserRole): Promise<AugmentedUser> {
  const url = `/users/${userId}/role`

  log('Toggle role', { url, role })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse()['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'PUT',
    body: JSON.stringify({ user: { role: role } }),
  }).then((u) => toAugmentedUser(u))
}

export async function UpdateUser(tenants: TenantRef[], userId: string, updates: UserUpdate): Promise<AugmentedUser> {
  const url = `/users/${userId}`

  log('updating user', { url, updates })

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse()['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'PUT',
    body: JSON.stringify({ tenant_id: `${tenants.map((t) => t.id)}`, user: updates }),
  }).then((u) => toAugmentedUser(u))
}

export async function NewUpdateUser(
  isMockUpEnabled: boolean,
  tenants: UserPermissions[],
  accessPlanIds: number[],
  user: UserRef,
): Promise<AugmentedUser> {
  const url = '/users'
  log('New update user', { url, tenants })

  if (isMockUpEnabled) {
    return returnMock(toAugmentedUser(mockUserResponse(user.email, user.name)['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'PUT',
    body: JSON.stringify({
      tenant_id: `${tenants.map((t) => t.id)}`,
      users: [user],
      tenants: tenants.map((tenant) => ({ ...tenant, discountPlan: undefined, accessPlan: undefined })),
      access_plan_ids: accessPlanIds,
    }),
  }).then((u) => toAugmentedUser(u))
}

export async function AddUserToTenants(
  tenantIds: number[],
  userId: string,
  email: string,
  role: DashboardAccessType,
): Promise<AugmentedUser> {
  let url = `/users/add`

  log('adding user to tenants', { url })
  if (configProvider.config.mockEnabled) {
    return Promise.resolve(toAugmentedUser(mockUserResponse()['data'][0]))
  }

  return apiClient<User>(url, {
    method: 'POST',
    body: JSON.stringify({ id: userId, role: role, email: email, tenant_ids: tenantIds }),
  }).then((u) => toAugmentedUser(u))
}

export async function SearchEntityOwners(term?: string): Promise<UserRef[]> {
  let url = '/users/search_entity_owner'
  let queryParams: { [key: string]: string } = {}

  if (term) {
    queryParams['term'] = term
  }

  url = addQueryString(url, queryParams)

  if (configProvider.config.mockEnabled) {
    return Promise.resolve(mockUserWallet)
  }
  return apiClient(url, {})
}
